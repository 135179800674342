.input-file-container {
    position: relative;
    // width: 225px;
    // text-align:center;
  } 
  .icon {
      width: 2vw;
      margin-bottom: -5%;padding: 0 3%;
  }
  .js .input-file-trigger {
    display: block;
    padding: 14px 20px;
    background: #fff;
    color: $black;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
  }
  .js .input-file {
    position: absolute;
    top: 0; left: 0;
    width: 100px;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
  }
  .js .input-file:hover + .input-file-trigger,
  .js .input-file:focus + .input-file-trigger,
  .js .input-file-trigger:hover,
  .js .input-file-trigger:focus {
    // background: #34629E;
    color: #22c5d3;
    cursor: pointer;
  }
  
  .file-return {
    margin: 0;
  }
  .file-return:not(:empty) {
    margin: 1em 0;
  }
  .js .file-return {
    font-style: italic;
    font-size: .9em;
    font-weight: bold;
  }
  .js .file-return:not(:empty):before {
    content: "Selected file: ";
    font-style: normal;
    font-weight: normal;
  }
  .span-kkm{
      color: #22c5d3;
      cursor: pointer;
  }

  .input-span{
    // display: flex;
    // cursor: pointer;
    height: 32px;
    justify-content: center;
    align-items: center;
    input {    
      font-family: inherit;
      font-size: 13px;
      font-weight: 600;
      opacity: .8;
      padding: 0;
      width: 0px;
      margin: 0;
      border: none;
      outline: none;
      transition: width 200ms ease-in-out;
    }

    &:hover{
      input {
        width: 200px;
        margin: 0 10px;

        @include isPhone() {
          width: 140px;
        }
      }
      .span-kkm{   
        display: none;
        transition: width 200ms ease-in-out;
      }
    }
  }
  .processUpload{    
    transition: width 200ms ease-in-out;
  }
  .kelas .list-silabus ul {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  .kelas .list-raport ul {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  .kelas .list-silabus li {    
    border-bottom: 1px solid black;
    padding: 5% 2%;  
    .icon {
      width: 1.2vw;
      margin-bottom: -2%;
      padding: 0 3%;
    }
  }
  .kelas .list-raport li {    
    padding: 1% 0%;  
    .icon {
      width: 1.2vw;
      margin-bottom: -1%;
      padding: 0 2%;
    }
  }
  .pad .js .input-file-container {
    margin-left: 10%;
    .input-file-trigger {
      padding: 5px 20px;
    }
    .icon {
      width: 2vw;
      margin-bottom: -3%;
      padding: 0 3%;
    }
}
.table .table-row {
  
  position: relative;
  border-bottom: 1px solid $gray-more;
  > a {
    display: flex;
    padding: 5px;

    // justify-content: space-between;
    >* {
      flex: 1;
    }

    >:last-child {
      text-align: right;
    }
    .sub-total{
      text-align: right;
      max-width: 20px;
    }
  }
  >a {
    color: inherit;

    &:hover {
      text-decoration: none;
      background-color: #f8f7f7;
    }
  }
}
.rompel-raport 
{
  a{
      text-decoration: none;
      // color: black;
  }
  .icon{
    width: 1vw;
    margin-bottom: -2%;
    padding: 0 2%;
    cursor: pointer;
  }
}

$contact-us-section-padding: 2rem;


.lkg-form {
  padding-top: 2%;
  input[type=text],
  input[type=email],
  textarea {
    margin-bottom: 1.5rem;
  }
  textarea {
    margin: 0px 0px 5px; width: 700px; height: 138px;
  }
}

.lkg-form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    text-align: center
  }

  .lkg-file-button {
    background: transparent;
    color: darkgray;
  }
}

.lkg-result {
  .container {
    border: 2px solid #ccc;
    background-color: #eee;
    border-radius: 5px;
    padding: 16px;
    margin: 16px 0;
  }
  
  /* Clear floats after containers */
  .container::after {
    content: "";
    clear: both;
    display: table;
  }
  
  /* Float images inside the container to the left. Add a right margin, and style the image as a circle */
  .container img {
    float: left;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  /* Increase the font-size of a span element */
  .container span {
    font-size: 20px;
    // margin-right: 15px;
  }
  
  /* Add media queries for responsiveness. This will center both the text and the image inside the container */
  @media (max-width: 500px) {
    .container {
      text-align: center;
    }
  
    .container img {
      margin: auto;
      float: none;
      display: block;
    }
  }
}
.vl {
  border-left: 2px solid darkgray;
  height: 40vh;
  flex: 0.1;
}
.table-nanana {
  width: 100%;
  tbody{
    tr {
      td{
        padding: 2% 0;
      }
      cursor: pointer;
    }
  }
}
.modal{
  z-index: 9999;
}
.modal-card-head,
.modal-card-foot {
  background-color: white;
  border: none;
  display: flex;
  flex-direction: row;

  >button {
    margin: 0 4px;
  }
}
.modal-background {
  background-color: rgba(10, 10, 10, 0.64);
}
.table:not(:last-child){
  margin-bottom: 0;
}
.input-siswa{
  height: inherit;
}