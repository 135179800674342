$red: #ff5a5f;
$green: #00a699;
$blue: #2490e5;
$orange:#fc642d;
$black: #484848;
$gray: #767676;
$gray-more: #d6d6d6;
$smoke: #f3f3f3;
$stabilo: #bdff3b;
$white: #ffffff;
$blugreen: #22c5d3;

$space-t: 8px;
$space-s: 16px;
$space-b: 24px;
$space-l: 48px;
$space-x: 64px;

$tablet-width: 767px;
$desktop-width: 1100px;
$large-width: 1400px;

@mixin isPhone {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin isTablet {
  @media only screen and (max-width: $desktop-width) {
    @content;
  }
}

@mixin isLarge {
  @media only screen and (min-width: $large-width) {
    @content;
  }
}

@function fontSize($size) {
  @return calc("$size" + 1vw);
}

@mixin figure {
  margin: 40px 0;
  padding: 0;
  img {
    width: 100%;
    object-fit: cover;
  }
  @content
};

@mixin grid($size: 200px) {
  margin: 40px 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($size, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  >*{
    padding: 0;
    @content;
  }
}