body {
  >* {
    display: grid;
    grid-template-columns: 35vw 1fr;

    &::before {
      content: '';
    }

    @include isPhone() {
      display: block;

      &::before {
        content: none;
      }
    }
  }
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 35vw;
  bottom: 0;
  background-color: #323635;
  color: $white;
  background-image: url(/assets/img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    background-color: #1e498ccf;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  a {
    color: inherit;
  }

  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 4vw;
    z-index: 999;
    align-items: flex-end;
  }

  >header {
    // display: none;
    padding: 4vw 4vw 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h4 {
      a {
        transition: all 250em ease-in-out;
        color: inherit;

        &:hover {
          text-decoration: none;
          opacity: .8;
        }
      }
    }

    .menu {
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: 14px;
      height: 2px;
      background-color: #fafafa;

      &::before,
      &::after {
        cursor: pointer;
        content: '';
        position: absolute;
        width: 18px;
        height: 2px;
        background-color: inherit;
        top: -6px;
        left: 0;
      }

      &::after {
        top: 6px;
      }
    }

  }

  >footer {
    padding: 2vw 4vw;
    z-index: 999;
    font-size: .9em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;

    a {
      display: inline-block;
      margin: 0 5px;

      &::after {
        content: '•';
        display: inline-block;
        margin-left: 9px;
        opacity: .75;
      }

      &:last-child {
        margin: 0;

        &::after {
          content: '';
        }
      }
    }
  }

  img.avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #ffffff;
    border: 2px solid #fff;
  }

  @include isPhone() {
    width: 100vw;
    left: -100vw;
  }

}

main {
  background-color: #ffffff;
  padding: 20px 0;

  >* {
    padding: 2vw 4vw;
  }
}

.fit-read {
  p {
    max-width: 540px;
  }
}

.pad {
  padding: 40px 0 0;
}

.center {
  text-align: center;
}

.stack {
  display: flex;
  flex-direction: column;
  >* {
    margin-bottom: 5px;
  }
}
.soal{
  >div {
    display: flex;
    align-items: center;

    input[type=text]{
      flex: 1;
    }
  }
}

.list-soal {
  ol {
    list-style-type: upper-alpha;
    column-count: 2;
  }
}

.grid-col {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  &.stat {

    // a {
    //   color: inherit;
    //   &:hover{
    //     text-decoration: none;
    //   }
    // }
    >* {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      background-color: whitesmoke;
      padding: 20px;
      text-align: center;
      transition: all 150ms ease-in-out;

      &:hover {
        background-color: #e8e8e8;
        transform: scale(1.05)
      }
    }

    h3 {
      flex: 1;
      font-size: 3.5em;
    }
  }
}

.info-list {
  >* {
    display: flex;

    >:last-child::before {
      content: ':';
      margin: 0 5px;
    }

    @include isPhone() {
      flex-direction: column;

      >:last-child {
        &::before {
          content: '';
          margin: 0;
        }

        margin-bottom: 10px;
      }
    }
  }
}

.table {
  position: relative;

  header {
    font-weight: bold;
    border-bottom: 2px solid $black;
  }

  >* {
    display: flex;
    padding: 5px;
    border-bottom: 1px solid $gray-more;

    // justify-content: space-between;
    >* {
      flex: 1;
    }

    >:last-child {
      text-align: right;
    }
    .sub-total{
      text-align: right;
      max-width: 20px;
    }
  }

  >a {
    color: inherit;

    &:hover {
      text-decoration: none;
      background-color: #f8f7f7;
    }
  }
}

.media-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 40px;

  &.mini {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  figure {
    margin: 0;
    padding: 0;
    display: block;
    padding-top: 56.25%;
    position: relative;
    background: $gray-more;
    overflow: hidden;
    margin-bottom: 20px;

    .cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      transition: transform 500ms ease-in-out;

      &:hover {
        transform: scale(1.1)
      }
    }
  }

  .full{
    // overflow: visible !important;
    height: 9em !important;
  }

  .info {
    height: 3.75em;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    a {
      color: inherit;
    }

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 1.5em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
  }

  .tag {
    font-size: .8em;
    color: $gray;

    &::after {
      content: ',';
      margin-right: 2px;
    }

    &:last-child::after {
      content: '';
    }
  }

}

.book-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 40px;

  a {
    display: flex;
    color: inherit;

    img {
      height: 160px;
    }

    span {
      padding: 0 20px;
    }
  }
}


select.clear {
  border: none;
  padding: 0;
  margin: 0;
}

.mapel {
  display: flex;

  .table {
    font-size: .9em;
    min-width: 140px;
    margin-left: 40px;
    cursor: pointer;
  }

  .active {
    font-weight: bold;
  }
}


.kelas {
  display: flex;

  >* {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  h4 {
    margin-bottom: 10px;
  }

  .list-guru {
    cursor: pointer;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &.selected {

        margin-bottom: 5px;

        li {
          color: $blugreen;
        }
      }
    }
  }
}

.tabs {
  margin: 40px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid $gray-more;

  >a {
    flex: 1;
    color: $gray;
    text-align: center;
    text-decoration: none !important;  
    padding: 10px;
    margin-bottom: -2px;
    border-bottom: 4px solid transparent;
    &:hover {
      color: $black;
      border-color: $gray;
    }
    &.active {
      color: $blugreen;
      border-color: $blugreen;
    }
  }
}

.media-placeholder{
  background: whitesmoke;
  height: 250px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 96px;
    opacity: .5;
    cursor: pointer;
  }
}

.calendar-month{
  display: flex;
  flex-flow: wrap;
  > * {
    margin: 5px;
  }

  header {
    display: flex;
    justify-content: center;
    max-width: 240px;
    h4{
      font-size: 1em;
    }
  }
}

.calendar {
  display: flex;
  flex-flow: wrap;
  list-style: none;
  padding: 0;
  max-width: 240px;
  position: relative;
  font-size: .8em;
  > li {
    cursor: pointer;
    margin: 1px;
    padding: 8px;
    width: calc(100%/7 - 20px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9fafb;
    // box-shadow: 0 0 1px $gray-more;

    &:hover{
      background-color: #e4e4e4;
    }
        
    &:empty {
      background: transparent !important;
      cursor: default;
    }

  }

  .selected {
    background:$blugreen !important;
    color: $white;
    font-weight: bold;
  }
}