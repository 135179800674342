.materi {
  figure {
    margin: 0;
    padding: 0;
    display: block;
    padding-top: 56.25%;
    position: relative;
    background: $gray-more;
    overflow: hidden;
    margin-bottom: 20px;

    .player {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      transition: transform 500ms ease-in-out;
      iframe, video, image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #ffffff;
    border: 2px solid #fff;
  }

  .tag {
    font-size: .8em;
    color: $gray;

    &::after {
      content: ',';
      margin-right: 2px;
    }

    &:last-child::after {
      content: '';
    }
  }

}


.diskusi {
  display: flex;
  flex-direction: column;
  max-width: 640px;

  textarea {
    height: 80px;
    resize: none;
    margin: 10px 0;
  }

  .list {
    padding: 40px 0;
    >div {
      padding-bottom: 20px;
    }
  }
}