div.list {
  box-shadow: none;
  border-radius: none;
}

.entry-form {
  display: grid;
  grid-template-columns: 160px minmax(200px, 1fr);
  padding: 20px;
  &>*{
    margin-bottom: 8px;
  }
  .control {
    display: flex;
    &>*{
      flex: 1;
    }
  }
}