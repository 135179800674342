.login{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  grid-template-columns: 55vw 1fr;
  
  aside {
    width: 55vw;
    >section {
      align-items: flex-start;
      justify-content: flex-start;
      display: flex;
      >article {
        flex: 1;
        font-size: 1.25em;
        h1{
          font-size: 3em;
        }
      }
    }
  }

  main {
    padding: 0;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    >section {
      padding: 4vw;

      header {
        
      }
    }
  }
}