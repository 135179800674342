// @import 'bulma';
@import 'vars.scss';

@import "../../node_modules/bulma/sass/utilities/_all";
// @import "../../node_modules/bulma/sass/elements/_all";
@import "../../node_modules/bulma/sass/form/_all";
@import "../../node_modules/bulma/sass/components/_all";
@import "../../node_modules/bulma/sass/grid/_all";
@import "../../node_modules/bulma/sass/layout/_all";

body {
  font-family: 'Cera Pro', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1em;
  line-height: 1.35;
  color: $black;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

 
@import 'typo.scss';
@import 'layout.scss';
@import 'login.scss';
@import 'form.scss';
@import 'media.scss';
@import 'hack.scss';