@font-face {
    font-family: 'Cera Pro';
    src: url('./cerapro/CeraPro-Black.woff2') format('woff2'),
        url('./cerapro/CeraPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./cerapro/CeraPro-Regular.woff2') format('woff2'),
        url('./cerapro/CeraPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./cerapro/CeraPro-Bold.woff2') format('woff2'),
        url('./cerapro/CeraPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./cerapro/CeraPro-Light.woff2') format('woff2'),
        url('./cerapro/CeraPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

