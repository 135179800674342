$accent: $blugreen;

h1, h2, h3, h4, p {
  margin: 0;
  line-height: 1.25;
}

p{
  margin: 10px 0;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4,h5 {
  font-size: 1.25em;
}
h5{
  font-weight: normal;
}
.italic {
  font-style: italic;
}
.small
,small, 
figcaption {
  display: inline-block;
  font-size: .85em;
  line-height: 1.2;
}

.micro {
  font-size: .75em;
  line-height: 1;
}
summary,
blockquote{
  font-size: 1.35em;
  line-height: 1.25;  
}

summary{
  font-weight: bold;
  border: none;
}

.inline{
  >* {
    display: inline;
  }
}
.large{
  font-size: 1.15em;
}
.light{
  font-weight: 300;
}
.bold{
  font-weight: 700;
}
.red{
  color: $red;
}
.green{
  color: $green;
}
.blue{
  color: $blue;
}

// article>header{
//   h1, h2 {
//     font-size: 2.5em;
//     line-height: 1;
//     letter-spacing: -2px;
//     display: inline-block;
//   }
// }

mark{
  background: $stabilo;
}

a{
  color: $accent;
  text-decoration: none;
  cursor: pointer;
  // font-weight: medium;
  &:hover{
    text-decoration: underline;
  }

  &.clean{
    color: inherit;
  }
}

ol,ul{
  &.clean {
    list-style: none;
    padding: 0;
  }
}

input,
button,
select,
textarea {
  font: inherit;
  outline: none;
  padding: 4px 5px;
  border: 1px solid;
  border-color: $gray-more;
  margin-bottom: 5px;

  &:disabled{
    opacity: .5;
    cursor: default;
  }
}

input {
  &.holo {
    border: 0;
    border-bottom: 1px solid;
    border-color: $gray-more;
    padding: 1px 0;
  }

  &:focus {
    border-color: $accent;
  }
}

.button
,button {
  display: inline-block;
  background-color: $accent;
  border-color: $accent;
  color: $white;
  border-radius: 2px;
  padding: 8px 15px;
  cursor: pointer;

  @include isPhone() {
    padding: 10px 25px;
  }

  
  &:hover {
    text-decoration: none;
  }

  &.holo {
    background-color: transparent;
    color: $accent;
    &.black {
      color: $black;
      border-color: $black;
    }
  }

  &:active {
    transform: scale(.97)
  }

  &.red {
    background-color: $red;
    border-color: $red;
    color: $white;
  }

  &.green {
    background-color: $green;
    border-color: $green;
    color: $white;
  }

  &.small {
    font-size: 14px;
    line-height: 18px;
    padding: 3px 8px;
  }

  &.rounded {
    border-radius: 50px;
    padding: 5px 20px;
  }
  &.clear {
    border: 0;
    padding: 0 10px;
    background-color: transparent;
    color: $accent;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      transform: none;
    }
  }

  &.large {
    padding: 10px 80px;
    font-weight: bold;
    border-width: 2px;
    border-radius: 5px;
  }
}

input[type=checkbox]{
}

.switch {
  width: 30px;
  height: 5px;
  border-radius: 5px;
  background: #ccc;
  position: relative;
  &::before{
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    top: -5px;
    position: absolute;
    background: $accent;
    border-radius: 50%;
    cursor: pointer;
  }

  &[checked]{
    right: 0;
  }
}

.field {
  max-width: 320px;
  padding: 0;
  border: none;
  display: flex;
  border: 1px solid;
  border-color: #9d9d9d;
  input,
  button {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    color: $black;
    background-color: $white;
  }

  button {
    display: flex;
    padding: 8px;
    min-width: 32px;
    justify-content: center;
    >img {
      height: 16px;
    }
  }

  input {
    border-right: 1px solid;
    padding: 2px 10px;
    flex: 1;
  }
}

.error{
  color: $red;
}

a.icon {
  display: inline-flex;
  justify-content: center;
  
  img{
    height: 24px;
    margin-right: 10px;
  }
}

label{
  font-weight: bold;
}